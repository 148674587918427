import React from "react";
import { useTranslation } from "react-i18next";
import ClientLogo from "../../assets/images/logo1-removebg-preview.png";

import { FlyInComponent } from "../../hook/ScrollEffect";

export default function Clients() {
  const { t } = useTranslation();

  const clients = [
    {
      image: ClientLogo,
      title: "Tista Sushi",
      link: "https://tista-sushi.no/"
    },
  ];

  const handleClientClick = (link) => {
    window.open(link, "_blank", "noopener,noreferrer");
  };

  return (
    <div className="max-w-3xl my-28 md:mx-auto sm:text-center md:mb-12">
      {/* Heading with fly-in effect */}
      <FlyInComponent direction="y">
        <h1 className="uppercase text-5xl text-primary text-center font-bold mt-64 mb-32">
          {t("clients")}
        </h1>
      </FlyInComponent>

      {/* Features grid */}
      <div className="grid gap-28 row-gap-12 lg:grid-cols-2">
        {clients.map((client, index) => (
          <FlyInComponent key={index}  index={index}>
            <div
              className="max-w-md mx-auto text-center group relative cursor-pointer"
              onClick={() => handleClientClick(client.link)}
            >
              <div
                className="mx-auto  transition-transform transform hover:scale-105 duration-500 ease-in-out"
              >
                <img
                  src={client.image}
                  alt={client.title}
                  className=" w-1/2 mx-auto object-cover"
                />
              </div>
              <h3 className="my-6 text-xl uppercase text-primary">
                {client.title}
              </h3>
            </div>
          </FlyInComponent>
        ))}
      </div>
    </div>
  );
}

import { useEffect, useState, useRef } from 'react';

function useIntersectionObserver(ref, options) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const currentRef = ref.current;
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [options, ref]);

  return isVisible;
}

export const FlyInComponent = ({ children, threshold = 0.5, direction = "y", index = 0 }) => {
  const ref = useRef();
  const isVisible = useIntersectionObserver(ref, { threshold });

  // Use 3D transforms for GPU acceleration
  const translateClass =
    direction === "x"
      ? index % 2 === 0
        ? "translate3d(-20px, 0, 0)"
        : "translate3d(20px, 0, 0)"
      : "translate3d(0, 20px, 0)";

  return (
    <div
      ref={ref}
      className={`transition-all duration-500 ease-in-out will-change-transform opacity-0 ${
        isVisible ? "transform translate3d(0, 0, 0) opacity-100" : `transform ${translateClass}`
      }`}
      style={{
        backfaceVisibility: "hidden",
        transitionTimingFunction: "ease-in-out", // Fine-tuned transition timing
      }}
    >
      {children}
    </div>
  );
};

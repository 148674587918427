import React from "react";
import { useTranslation } from "react-i18next";

import { FlyInComponent } from "../../hook/ScrollEffect";
import { PrimaryButton } from "../Buttons";

export default function Offers() {
  const { t } = useTranslation();

  return (
    <div className="max-w-5xl my-28 md:mx-auto sm:text-center md:mb-12">
      {/* Heading with fly-in effect */}
      {/* <FlyInComponent direction="y">
        <h1 className="uppercase text-5xl text-primary text-center font-bold mt-64 mb-32">
          {t("we-offer")}
        </h1>
      </FlyInComponent> */}

      {/* Features grid */}
      <div className="text-center text-3xl md:text-5xl py-14">WHY US?</div>
      <div className="grid gap-12 row-gap-12 lg:grid-cols-2">
        <FlyInComponent direction="y">
          <div className="glass p-6 py-12 h-[250px]">
            <h2 className="uppercase text-3xl my-4 text-white">
              <span className=" text-4xl text-primary">zero</span> upfront cost
            </h2>
            <div className="text-start w-3/4 mx-auto">
              <p className="text-md text-white">
                {" "}
                &#8226; {t("no-initial-fee")}
              </p>
              <p className="text-md text-white">&#8226; {t("free-trial")}</p>
            </div>
          </div>
        </FlyInComponent>
        <FlyInComponent direction="y">
          <div className="glass p-6 py-12 h-[250px]">
            <h2 className="uppercase text-3xl my-4 text-white">
              <span className=" text-4xl text-primary">Tailored</span> Solution
            </h2>
            <div className="text-start w-3/4 mx-auto">
              <p className="text-md text-white">
                {" "}
                &#8226; {t("customize-build")}
              </p>
              <p className="text-md text-white">&#8226; {t("focus-matters")}</p>
            </div>
          </div>
        </FlyInComponent>
        <FlyInComponent direction="y">
          <div className="glass p-6 py-12 h-[250px]">
            <h2 className="uppercase text-3xl my-4 text-white">
              <span className=" text-4xl text-primary">Compliance</span> System
            </h2>
            <div className="text-start w-full md:w-3/4 mx-auto">
              <p className="text-md text-white">
                &#8226; {t("certified-skat")}
              </p>
              <p className="text-md text-white">
                {" "}
                &#8226; {t("certified-compliance")}
              </p>
            </div>
          </div>
        </FlyInComponent>
        <FlyInComponent direction="y">
          <div className="glass p-6 py-12 h-[250px]">
            <h2 className="uppercase text-3xl my-4 text-white">
              <span className=" text-4xl text-primary">Simplified</span>{" "}
              workflow
            </h2>
            <div className="text-start w-3/4 mx-auto">
              <p className="text-md text-white">
                &#8226; {t("simplified-ops")}
              </p>
              <p className="text-md text-white">
                {" "}
                &#8226; {t("simplified-design")}
              </p>
            </div>
          </div>
        </FlyInComponent>
        <FlyInComponent direction="y">
          <div className="glass p-6 py-12 h-[250px]">
            <h2 className="uppercase text-3xl my-4 text-white">
              <span className=" text-4xl text-primary">Fast</span> responsive
              support
            </h2>
            <div className="text-start w-3/4 mx-auto">
              <p className="text-md text-white">
                &#8226; {t("support-reliable")}
              </p>
              <p className="text-md text-white">
                {" "}
                &#8226; {t("support-always")}
              </p>
            </div>
          </div>
        </FlyInComponent>
        <FlyInComponent direction="y">
          <div className="glass p-6 py-12 h-[250px]">
            <h2 className="uppercase text-3xl my-4 text-white">
              <span className=" text-4xl text-primary">free</span> SEO website
            </h2>
            <div className="text-start w-3/4 mx-auto">
              <p className="text-md text-white">
                &#8226; {t("online-visibility")}
              </p>
              <p className="text-md text-white">
                &#8226; {t("integrate-order-system")}
              </p>
            </div>
          </div>
        </FlyInComponent>
      </div>
      <FlyInComponent direction="y">
        <div className="text-2xl uppercase my-20 text-center">
          <PrimaryButton
            label={t("contact-us-now")}
            onClick={() =>
              document
                .getElementById("contact")
                .scrollIntoView({ behavior: "smooth" })
            }
          />
        </div>
      </FlyInComponent>
    </div>
  );
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons"; // Import the icons
import Logo from "../Logo";

export function Footer() {
  return (
    <footer className=" justify-center p-4 mt-72 mb-16">
      <div className="text-center lg:flex">
        <div className="mx-auto flex-1 lg:text-end lg:me-32 content-center mb-6">
          <Logo />
        </div>
        {/* Email and Phone Section */}
        <div className="contact-info text-lg flex-1 lg:text-start">
          <p className="mb-6">
            <FontAwesomeIcon icon={faEnvelope} className="icon" /> Email:{" "}
            <div>
              <a
                href="mailto:contact@tech-intelligence.no"
                className="hover:text-primary"
              >
                contact@tech-intelligence.no
              </a>
            </div>
          </p>
          <p >
            <FontAwesomeIcon icon={faPhone} className="icon" /> Phone:{" "}
            <div className="flex flex-col">
              <a href="tel:+4794798922" className="hover:text-primary">
                +4794798922 (English, Vietnamese)
              </a>
              <a href="tel:+4745670792" className="hover:text-primary">
                +4745670792 (Norsk)
              </a>
            </div>
          </p>
        </div>
      </div>
      <p className="mt-6 text-center">
        Copyright © {new Date().getFullYear()} - All rights reserved
      </p>
    </footer>
  );
}

import styles from "./index.module.css";
export function PrimaryButton({label, onClick}) {
    return (
      <button className={`${styles.primaryButton} inline-block py-2 px-4 rounded-md`} onClick={onClick}>
        {label}
      </button>
    );
}
export function SecondaryButton({label, onClick}) {
    return (
        <button className={styles.secondaryButton} onClick={onClick} >{label}</button>
    );
}
import React from "react";
import { useTranslation } from "react-i18next";
import FeatureOne from "../../assets/images/onlineordering.png";
import FeatureTwo from "../../assets/images/pos.png";
import FeatureThree from "../../assets/images/orderlater.gif";
import VisaLogo from "../../assets/images/Visa New 2021.png";
import VippsLogo from "../../assets/images/vipps.png";
import MasterLogo from "../../assets/images/masterlogo.png";
import BankLogo from "../../assets/images/bankaxept.png";
import FeatureFive from "../../assets/images/sms.gif";
import FeatureSix from "../../assets/images/administration.png";
import FeatureSeven from "../../assets/images/accounting.png";
import FeatureEight from "../../assets/images/web.png";

import { FlyInComponent } from "../../hook/ScrollEffect";

export default function Features() {
  const { t } = useTranslation();

  return (
    <div className="">
      {/* Subheading with fly-in effect */}
      <FlyInComponent direction="y">
        <div className="gradiendBackground w-full py-16 mx-auto px-5 md:px-32">
          <h1 className="uppercase text-5xl text-primary text-center font-bold py-32">
            {t("features")}
          </h1>
          <div className="glassColor bg-[#01768e33] p-6 md:p-12">
            <h2 className="mb-6 text-3xl">
              <span className="text-primary">Order </span>
              {t("features-content")}
            </h2>
            <div className="flex flex-col md:flex-row md:flex-wrap gap-8 text-xl pt-6">
              <div className="md:w-[300px]">&#8226; Self-service POS</div>
              <div className="md:w-[300px]">&#8226; Online Order</div>
              <div className="md:w-[300px]">&#8226; Cash Register System</div>
              <div className="md:w-[300px]">&#8226; Pay with Card, Vipps</div>
              <div className="md:w-[300px]">
                &#8226; Kitchen Display System (KDS)
              </div>
              <div className="md:w-[300px]">
                &#8226; Order Display Screen (ODS)
              </div>
              <div className="md:w-[300px]">&#8226; SMS Service</div>
              <div className="md:w-[300px]">&#8226; Sales Reporting</div>
              <div className="md:w-[300px]">&#8226; Accounting Integration</div>
              <div className="md:w-[300px]">
                &#8226; Custom Graphic design
              </div>
              <div className="md:w-[300px]">&#8226; SEO website</div>
            </div>
          </div>
        </div>
      </FlyInComponent>

      <div>
        {/* Feature one: online ordering */}

        <FlyInComponent direction="y">
          <div className=" mx-auto text-center group relative">
            <h3 className="my-6 text-3xl font-bold uppercase text-darkGreen">
              {t("feature-one")}
            </h3>
            <p className="mb-3 text-lg text-start px-4 md:w-3/4 lg:w-1/2 mx-auto">
              {t("content-feature-one")}
            </p>
            <div className="lg:w-3/4 mx-auto">
              <img
                src={FeatureOne}
                alt={t("feature-one")}
                className=" w-full h-full object-cover"
              />
            </div>
          </div>
        </FlyInComponent>

        {/* Feature two: free up staff */}

        <FlyInComponent direction="y">
          <div className=" mx-auto text-center group lg:flex my-24">
            <div className=" mx-auto bg-gradient-secondary p-2 rounded-tr-full">
              <img
                src={FeatureTwo}
                alt={t("feature-two")}
                className=" w-full h-full object-cover"
              />
            </div>
            <div className="content-center">
              <h3 className="my-6 text-3xl font-bold uppercase text-darkGreen">
                {t("feature-two")}
              </h3>
              <p className="mb-3 text-lg text-start px-4 md:w-3/4  mx-auto">
                {t("content-feature-two")}
              </p>
            </div>
          </div>
        </FlyInComponent>
        {/* Feature three: order later */}

        <FlyInComponent direction="y">
          <div className=" mx-auto text-center group my-24">
            <div className="content-center">
              <h3 className="my-6 text-3xl font-bold uppercase text-darkGreen">
                {t("feature-three")}
              </h3>
              <p className="mb-3 text-lg text-start px-4 md:w-3/4 lg:w-1/2 mx-auto">
                {t("content-feature-three")}
              </p>
              <div className="lg:w-1/2 mx-auto p-2">
                <img
                  src={FeatureThree}
                  alt={t("feature-three")}
                  className=" w-full h-full object-cover"
                />
              </div>
            </div>
          </div>
        </FlyInComponent>
        {/* Feature four: payment */}

        <FlyInComponent direction="y">
          <div className=" mx-auto text-center group my-24">
            <div className="content-center">
              <h3 className="my-6 text-3xl font-bold uppercase text-darkGreen">
                {t("feature-four")}
              </h3>
              <p className="mb-3 text-lg text-start px-4 md:w-3/4 lg:w-1/2 mx-auto">
                {t("content-feature-four")}
              </p>
              <div className="flex flex-wrap mx-auto justify-center mb-10 lg:w-3/4">
                <img src={VippsLogo} alt="Vipps logo" className=" w-52 m-5" />
                <img
                  src={VisaLogo}
                  alt="Visa card logo"
                  className=" w-32 m-5"
                />
                <img
                  src={BankLogo}
                  alt="bank axept logo"
                  className=" w-32 m-5"
                />
                <img
                  src={MasterLogo}
                  alt="Master card logo"
                  className=" w-32 m-5"
                />
              </div>
            </div>
          </div>
        </FlyInComponent>

        {/* Feature five: sms */}

        <FlyInComponent direction="y">
          <div className=" mx-auto text-center group lg:flex my-24">
            <div className="content-center flex-1">
              <h3 className="my-6 text-3xl font-bold uppercase text-darkGreen">
                {t("feature-five")}
              </h3>
              <p className="mb-3 text-lg text-start px-4 md:w-3/4  mx-auto">
                {t("content-feature-five")}
              </p>
            </div>
            <div className=" mx-auto flex-1">
              <img
                src={FeatureFive}
                alt={t("feature-five")}
                className=" w-full h-full object-cover"
              />
            </div>
          </div>
        </FlyInComponent>

        {/* Feature six: advanced administration */}

        <FlyInComponent direction="y">
          <div className=" mx-auto text-center group relative">
            <h3 className="my-6 text-3xl font-bold uppercase text-darkGreen">
              {t("feature-six")}
            </h3>
            <p className="mb-3 text-lg text-start px-4 md:w-3/4 lg:w-1/2 mx-auto">
              {t("content-feature-six")}
            </p>
            <div className="lg:w-3/4 mx-auto">
              <img
                src={FeatureSix}
                alt={t("feature-six")}
                className=" w-full h-full object-cover"
              />
            </div>
          </div>
        </FlyInComponent>

        {/* Feature seven: accounting */}

        <FlyInComponent direction="y">
          <div className=" mx-auto text-center group relative">
            <h3 className="my-6 text-3xl font-bold uppercase text-darkGreen">
              {t("feature-seven")}
            </h3>
            <p className="mb-3 text-lg text-start px-4 md:w-3/4 lg:w-1/2 mx-auto">
              {t("content-feature-seven")}
            </p>
            <div className="lg:w-3/4 mx-auto">
              <img
                src={FeatureSeven}
                alt={t("feature-seven")}
                className=" w-full h-full object-cover"
              />
            </div>
          </div>
        </FlyInComponent>

        {/* Feature eight: website */}

        <FlyInComponent direction="y">
          <div className=" mx-auto text-center group relative">
            <h3 className="my-6 text-3xl font-bold uppercase text-darkGreen">
              {t("feature-eight")}
            </h3>
            <p className="mb-3 text-lg text-start px-4 md:w-3/4 lg:w-1/2 mx-auto">
              {t("content-feature-eight")}
            </p>
            <div className="lg:w-3/4 mx-auto">
              <img
                src={FeatureEight}
                alt={t("feature-eight")}
                className=" w-full h-full object-cover"
              />
            </div>
          </div>
        </FlyInComponent>
      </div>
    </div>
  );
}
